<template>
  <div
    class="forum mfzj"
    v-infinite-scroll="loadMore"
    infinite-scroll-immediate="false"
    infinite-scroll-distance="0"
  >
    <div class="left">
      <el-affix :offset="Number(71)">
        <!-- 分区导航 -->
        <el-card>
          <el-scrollbar :max-height="maxHeight">
            <ul class="cate-ul">
              <li class="cate-item" v-for="item in pubData" :key="item.id" @click.stop="clickItem(item, 'cate')">
                <div :class="{ 'active': cateIndex === item.id, 'is-show': ~item.tags.map(i => i.id).indexOf(tagIndex) || ~showCateIds.indexOf(item.id) }">
                  <el-icon v-if="item.tags.length"><ArrowDownBold /></el-icon>
                  <TextExceedsTip
                    :content="item.name"
                    placement="right"
                  ></TextExceedsTip>
                </div>
                <transition name="draw" v-if="item.tags.length">
                  <ul class="categorize-ul" v-show="~item.tags.map(i => i.id).indexOf(tagIndex) || ~showCateIds.indexOf(item.id)" :style="{ '--height': `${(item.tags.length * 37) || 0}px`}">
                    <li class="tag-item" v-for="i in item.tags" :key="i.id" @click.stop="clickItem(i, 'tag')">
                      <div :class="{ 'active': tagIndex === i.id }">
                        <TextExceedsTip
                          :content="i.name"
                          className="tag-name"
                          placement="right"
                        ></TextExceedsTip>
                      </div>
                    </li>
                  </ul>
                </transition>
              </li>
            </ul>
          </el-scrollbar>
        </el-card>
      </el-affix>
    </div>
    <!-- 表格 表头切换 -->
    <div>
      <div class="post-list">
        <img v-if="postImg" :src="postImg"  />
        <!-- 表格数据 -->
        <el-card class="my-tab">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <template v-for="{label, name} in tabList" :key="name">
              <el-tab-pane :label="label" :name="name">
                <PostList :forumList="forumList" :isLoading="isLoading"></PostList
              ></el-tab-pane>
            </template>
          </el-tabs>
        </el-card>
      </div>
    </div>
    <HotTopic top="72"></HotTopic>
    <el-affix
      class="write"
      :offset="offset"
      @click="addForum"
    >
      <svg
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle opacity=".9" cx="30" cy="30" r="30" fill="#7972F0" />
        <path
          d="M28.605 44.139a9.878 9.878 0 0 1-2.343 2.73c.89.399 2.082.711 3.574.938a46.694 46.694 0 0 0-.996 1.71c-1.594-.359-2.95-.894-4.067-1.605-1.148.672-2.507 1.246-4.078 1.723a26.391 26.391 0 0 0-.89-1.512 23.679 23.679 0 0 0 3.527-1.277 10.394 10.394 0 0 1-1.547-1.828c-.906 1.445-1.945 2.672-3.117 3.68a23.929 23.929 0 0 0-.516-1.958c1.524-1.43 2.66-3.09 3.41-4.98h-2.94v-1.407c.218-.64.413-1.312.585-2.015l1.605.176a50.55 50.55 0 0 1-.48 1.804h1.688c.18-.664.359-1.437.539-2.32l1.699.14-.492 2.18h3.28c-.437-.43-.886-.859-1.347-1.289l1.031-.972a30.517 30.517 0 0 1 1.829 1.511l-.739.75h1.98v1.442h-6.48c-.117.328-.254.687-.41 1.078h5.695v1.3zm-3.855 1.886c.813-.578 1.445-1.187 1.898-1.828h-3.562a7.1 7.1 0 0 0 1.664 1.828zm6.938 1.149H30.62v-7.196h1.559V38.01h1.43v1.968h1.546v5.602c0 .367-.045.666-.134.897-.09.226-.215.38-.375.463-.16.078-.456.117-.885.117a36.306 36.306 0 0 0-.153-1.06V49.6h-1.43v-8.215h-.492v5.789zm1.921-1.377c.328.062.493-.08.493-.428v-3.984h-.493v4.412zm1.852 3.803v-6.082h1.898v-5.461h1.676v2.086h2.8v1.406h-2.8v1.969h2.192v6.07h-1.618v-.727h-2.53v.739H35.46zm4.148-4.711h-2.53v2.59h2.53v-2.59z"
          fill="#fff"
        />
        <rect x="25" y="20" width="8" height="2" rx="1" fill="#fff" />
        <rect x="25" y="24" width="10" height="2" rx="1" fill="#fff" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M33.707 16.778a1 1 0 0 0 1.414 1.415l4.199-4.199a1 1 0 0 0-1.415-1.414l-4.198 4.198zM23 13h8a1 1 0 1 1 0 2h-8v14h14v-9a1 1 0 1 1 2 0v9a2 2 0 0 1-2 2H23a2 2 0 0 1-2-2V15a2 2 0 0 1 2-2z"
          fill="#fff"
        />
      </svg>
    </el-affix>
  </div>
</template>

<script>
import { computed, onMounted, reactive, toRefs, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import PostList from './components/PostList'
import HotTopic from '@/components/HotTopic.vue'
import { getForumPostList } from '@/apis/forum.js'
import { useStore } from 'vuex'
export default {
  components: {
    PostList,
    HotTopic
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const state = reactive({
      activeName: '2',
      cateIndex: Number(route.query.cateId || 1),
      tagIndex: Number(route.query.tagId || 0),
      pages: 1,
      limit: 20,
      forumList: [],
      isLoading: true,
      pubData: computed(() => store.state.forum.pubData),
      offset: window.screen.height > 801 ? 635 : 450,
      postImg: [],
      showCateIds: [],
      spaceId: 0, // 广告位id
      maxHeight: document.body.clientHeight - 100,
      tabList: [
        { label: '最新发布', name: '2' },
        { label: '最新回复', name: '1' },
        { label: '热门', name: '3' },
        { label: '精华', name: '4' }
      ]
    })
    // 表头切换
    const handleClick = (tab, event) => {
      init()
    }
    // 获得分区
    store.dispatch('forum/getCatalists')

    watch(() => state.cateIndex, (newValue) => {
      state.postImg = state.pubData.find(i => i.id === newValue)?.img_url
    }, { immediate: true })

    // 当前分区
    function clickItem (item, type) {
      state.spaceId = item.space_id
      let queryParams = {}
      let isFlag = false
      if (type === 'tag') {
        // 分类
        state.tagIndex = item.id
        state.cateIndex = item.cate_id
        queryParams = {
          cateId: state.cateIndex,
          tagId: state.tagIndex
        }
      } else {
        // 区域
        state.cateIndex = item.id
        isFlag = state.cateIndex === Number(route.query.cateId) && !state.tagIndex
        state.tagIndex = 0
        queryParams = {
          cateId: state.cateIndex
        }
      }
      if (item?.tags?.length) {
        // 判断tag是否展开收起
        const index = state.showCateIds.indexOf(item.id)
        if (index > -1) {
          state.showCateIds.splice(index, 1)
        } else {
          state.showCateIds.push(item.id)
        }
      }

      document.scrollingElement.scrollTop = 0
      // 展开和分类才调用帖子列表接口
      console.log(isFlag)
      isFlag || init()
      // 解决浏览器回退定位对应模块
      router.replace({
        name: 'forum',
        query: queryParams
      })
    }

    // 获得分区中的帖子
    function getforumList () {
      getForumPostList({
        type: state.activeName,
        cate_id: state.cateIndex,
        tag_id: state.tagIndex,
        limit: state.limit,
        page: state.pages
      })
        .then((res) => {
          state.isLoading = false
          store.commit('setLoadMore', false)
          if (res.code === 0) {
            state.forumList.push(...res.data.data)
            if (res.data.data.length < state.limit) {
              if (
                (state.pages !== 1 && res.data.data.length === 0) ||
                (state.pages === 1 && res.data.data.length !== 0)
              ) {
                store.commit('setNoMore', true)
              }
            }
          }
        })
        .catch((err) => {
          store.commit('setLoadMore', false)
          store.commit('setNoMore', false)
          console.log(err)
        })
    }

    const loadMore = () => {
      if (
        state.forumList.length !== 0 &&
        !store.state.noMore &&
        !store.state.loadMore
      ) {
        store.commit('setLoadMore', true)
        state.pages += 1
        getforumList()
      }
    }

    function init () {
      state.isLoading = true
      state.pages = 1
      state.forumList = []
      store.commit('setLoadMore', false)
      store.commit('setNoMore', false)
      getforumList()
    }
    const addForum = () => {
      const params = {
        cateId: state.cateIndex
      }
      if (state.tagIndex) params.tagId = state.tagIndex
      router.push({
        name: 'PublishForum',
        query: params
      })
    }

    onMounted(() => {
      init()
      if (state.cateIndex && state.tagIndex) {
        state.showCateIds.push(state.cateIndex)
      }
    })
    return {
      ...toRefs(state),
      handleClick,
      router,
      clickItem,
      getforumList,
      store,
      loadMore,
      addForum
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../assets/less/myTab.less';
.forum {
  display: flex;
  img {
    width: 100%;
    height: 113px;
    margin-bottom: 4px;
    border-radius: 4px;
  }

  .el-tab-pane > div {
    overflow: auto;
  }
  .left {
    margin-right: 8px;
    width: 157px;
    .el-card__body {
      padding: 24px;
    }
    .cate-ul {
      padding: 10px;
      font-size: 14px;
      z-index: 9999;
      width: 155px;
      li {
        padding: 4px 0;
        div {
          display: block;
          color: @default-text-color;
          padding: 5px 0 5px 26px;
          // width: 125px;
          cursor: pointer;
          border-radius: 5px;
          position: relative;
          &.active {
            color: @active-text-color;
            font-weight: bold;
          }
          &:hover {
            background: rgba(121, 114, 240, 0.2);
            color: @active-text-color;
          }
          &.is-show {
            .el-icon{
              transform: rotate(180deg);
            }
          }
        }
        .el-icon{
          position: absolute;
          left: 5px;
          top: 8px;
          transition: all .2s;
        }
        &:last-child {
          border-bottom: 0;
        }
      }
    }
    .categorize-ul{
      overflow: hidden;
      li div{
        width: 100%;
        padding-left: 34px;
        &.active {
          :deep(.tag-name span) {
            color: @active-text-color;
            font-weight: bold;
          }
        }
        :deep(.tag-name span) {
          color: #666;
        }
      }
    }
  }
  .el-card {
    --el-card-padding: 0;
  }

  .post-list {
    width: 626px;
    min-height: 404px;
    margin-right: 8px;
  }
}
.write {
  text-align: right;
  cursor: pointer;
  max-width: 470px;
  margin: 0 auto;
  z-index: 0;
  :deep .el-affix--fixed {
    z-index: 0 !important;
    left: calc(50% + 150px) !important;
  }
}
.draw-enter-active,
.draw-leave-active {
  transition: all .2s;
}

.draw-enter-from,
.draw-leave-to{
  height: 0;
}

.draw-enter-to,
.draw-leave-from{
  height: var(--height);
}

@media (max-width: 1023px) {
  .forum .post-list {
    width: 100%;
  }
}
</style>
