
<template>
  <div class="table" v-loading="loading" v-if="!isNetWorkErr">
    <ul v-for="item in forumList" :key="item.id">
      <li>
        <div>
          <div>
            <userAvatar :user="item.user" size="34" fontSize="12" medalSize="50"></userAvatar>
            <p>{{item.user && item.user.name}}</p>
          </div>
          <div class="forum-content">
            <div class="forum-title">
              <p class="is-tags" v-if="item.is_top || item.is_hot || item.is_very_good || item.is_new">
                <span class="is-top" v-if="item.is_top">置顶</span>
                <span class="is-hot" v-if="item.is_hot">热</span>
                <span class="is-good" v-if="item.is_very_good">精</span>
                <span class="is-new" v-if="item.is_new">新</span>
              </p>
              <p class="title" v-html="formatHtml(item.title)"  @click="articalDetail(item.id)" ></p>
            </div>
            <p class="date">{{formatDate(item.created_at)}}</p>
          </div>
        </div>
        <p>回复：{{item.comment_count}}</p>
      </li>
    </ul>
    <empty-data v-if="!loading && forumList.length == 0"
      image="no-post"
      description="暂无帖子" size="170"></empty-data>
  </div>
  <template v-else>
    <empty-data image="network-error" description="网络超时，请重试" size="170"></empty-data>
  </template>

</template>

<script>
import { useRouter } from 'vue-router'
import { formatDate } from '@/utils/filterHtml.js'
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'
export default {
  props: ['forumList', 'isLoading'],
  setup (props) {
    const router = useRouter()
    const store = useStore()
    const isNetWorkErr = computed(() => store.state.isNetWorkErr)
    const loading = computed(() => props.isLoading)
    function articalDetail (id) {
      router.push({
        name: 'articalDetail',
        params: {
          id
        }
      })
    }

    return { articalDetail, formatDate, loading, isNetWorkErr }
  }
}
</script>

<style lang="less" scoped>
.table {
  font-size: 16px;
  min-height: 404px;
  .el-empty{
    padding-top: 130px;
  }

  ul li {
    display: flex;
    justify-content: space-between;
    padding: 15px 22px;
    border-bottom: 1px solid #F1F1F1;
    background-color: var(--el-color-white);
    color: @default-text-color;
    >div{
      display: flex;
      width: 90%;
    }
    .user-avatar{
      margin-right: 8px;
      &+p{
        margin-right: 10px;
        text-align: center;
        width: 50px;
      }
    }
    .forum-content{
      width: 90%;
    }
    p{
      line-height: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-top: 5px;
      font-size: 12px;
      &.title{
        font-size: 14px;
        margin-top: 0px;
        line-height: 18px;
        cursor: pointer;
        &:hover {
          color: @active-text-color;
        }
      }
      &.date{
        line-height: 16px;
        margin-top: 8px;
        color: @second-text-color;
      }
    }
    .forum-title{
      display: flex;
      align-items: center;
      margin-top: 4px;
      width: 100%;
      .is-tags{
        margin: 0 8px 0 0;
        overflow: initial;
        span{
          padding: 0px 4px 1px 4px;
          border-radius: 4px;
          font-size: 12px;
          display: inline-block;
          &:not(:first-child){
            margin-left: 4px;
          }
        }
        .is-top{
          border: 1px solid #FF8401;
          color: #FF8401;
        }
        .is-hot{
          border: 1px solid #F64545;
          color: #F64545;
        }
        .is-good{
          border: 1px solid @active-text-color;
          color: @active-text-color;
        }
        .is-new{
          border: 1px solid #41D8AA;
          color: #41D8AA;
        }
      }
    }
  }
}
</style>
